.vote-btn {
  font-size: 12px;
}

.gallery {
  img, video {
    width: 25%;

    @media (max-width: 768px) {
      width: 50%;
    }
  }
}
