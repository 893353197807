
.welcome {
  .fa-palette {
    color: $primary-text;
    font-size: 6rem;
  }

  hr {
    border: 0;
    height: 5px;
    background: $primary-text;
    border-radius: 5px;
    margin: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .welcome-logo {
    max-width: 100%;
    max-height: 100vh;
    height: auto;
  }

  .slogan {
    color: $primary-text;
    font-size: 12px;
    font-style: italic;
    font-family: monospace;
  }
}
