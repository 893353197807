@import '~bootstrap/scss/bootstrap';
@import './colors';
@import './buttons.scss';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import './layout.scss';
@import './welcome.scss';
@import './events.scss';

.body {
  font-family: Arial, Helvetica, sans-serif
}

h1, h2, h4, h5 {
  color: $primary;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

h3 {
  color: $secondary-text;
}

label {
  color: $secondary-text;
}

p {
  color: $primary-text;
}

a {
  color: $secondary-text;
}

.small-text {
  font-size: 12px;
}

.card , .modal {
  color: $secondary-text;
  p , h1, h5 {
    color: $secondary-text;
  }
}

.card {
  width: 18rem;
  min-height: 30rem;
}

img {
  border-radius: 5px;
}

.fa-instagram {
  color: $primary;
}
