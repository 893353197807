.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: $secondary-text;

  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $secondary-text;
  }
}
.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: $secondary-text;

  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: $secondary-text;
  }
}
