.layout-content {
  min-height: 100vh;
}

.transparent-background {
  background-color: #222;
  background-image: url('images/texture.jpeg')
}

// Navigation

.menu-logo {
  max-width: 92px;
}

.nav-links {
  ul {
    a {
      color: black;
      height: 100%;
      padding: 20px;
      &:hover {
        background-color: $primary;
      }
      display: inline-block;
    }

    li {
      display: inline-block;
      &.active {
        border-bottom: 4px solid $primary;
        font-weight: 700;
      }
    }
  }
}

footer {
  font-size: 12px;
  color: $secondary-text;
  p {
    color: $secondary-text;
  }
}
